import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

if (typeof window !== 'undefined') {
    window.location.href = "/contact-us";
}

const CallbackPage = () => (
    <Layout>
        <Seo title={'Request Callback'} lang={'en-gb'} />
    </Layout>
)

export default CallbackPage;